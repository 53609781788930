import * as React from "react";
import { initializeApp } from "firebase/app";
import dayjs from "dayjs";
import { RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ConfigProvider, notification, theme } from "antd";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";


import router from "router/router";
import relativeTime from "dayjs/plugin/relativeTime";

import handleError from "utils/handler/error";
// import { onMessageListener } from "./firebase";
// import { firebaseConfig } from "utils/googleAnalytics";
import queryKeys from "constants/reactQuery";
import SuspenseLoading from "components/loading/suspenseLoading/SuspenseLoading";


dayjs.extend(relativeTime);

notification.config({
  duration: 2,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error: any) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403 ||
          error?.response?.status === 404 ||
          error?.response?.status === 440
        ) {
          return false;
        }
        return failureCount < 1;
      },
      onError: (error) => handleError(error),
    },
    mutations: {
      onError: (error) => handleError(error),
    },
  },
});
export default function App() {
  // onMessageListener()
  //   .then((payload: any) => {
  //     console.log("Received foreground message:\n", payload);
  //     queryClient.invalidateQueries([queryKeys.auth.notifications]);

  //   })
  //   .catch((error) => console.log("Message listening error:\n", error));

  // firebase

  // React.useEffect(() => {
  //   initializeApp(firebaseConfig);
  // }, []);

  //

  
  // const [showTawk, setShowTawk] = React.useState(true);

  // React.useEffect(() => {
  //   const currentRoute = window.location.pathname;
  //   if (currentRoute.startsWith("/admin")) {
  //     setShowTawk(false);
  //   } else {
  //     setShowTawk(true);
  //   }
  // }, []);

  const appearance = {
    theme: "stripe" as "stripe", // or "night" or "flat"
  };

  return (
    <>
      <React.Suspense fallback={<SuspenseLoading />}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#EEBBB8",
            },
          }}
        >
          <QueryClientProvider client={queryClient}>
            <HelmetProvider>
              <GoogleOAuthProvider
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}
              >
              
                  <RouterProvider router={router} />
                  {/* {showTawk && (
        <TawkMessengerReact
          widgetId={process.env.REACT_APP_TAWK_WIDGETID}
          propertyId={process.env.REACT_APP_TAWK_PROPERTYID}
        />
      )} */}
              </GoogleOAuthProvider>
            </HelmetProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ConfigProvider>
      </React.Suspense>
    </>
  );
}
